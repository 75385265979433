import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Event } from "../../pojo/Event";
import { Task } from "../../pojo/Task";

@TypeManagerDecorator("s25-ng-task-assign")
@Component({
    selector: "s25-ng-task-assign",
    template: `
        @if (init) {
            <div>
                @if (!isTodo) {
                    <s25-ng-task-contacts-picker
                        [tasks]="taskItem"
                        (contactsChange)="contactsChange($event)"
                        [eventId]="taskItem.eventId"
                        [isOnBody]="true"
                        [afterSave]=""
                        [hideGroups]="true"
                    ></s25-ng-task-contacts-picker>
                }
                <div class="ngCpointer" (click)="toggleCollapse()">{{ collapse ? "&#x25BC;" : "&#x25B2;" }}</div>
                @if (!collapse) {
                    <div>
                        @for (status of approvalState; track status) {
                            <div>
                                <div class="ngBold">{{ status[0] }}</div>
                                <!--notify tasks-->
                                @if (taskType === 1 || taskType === 2) {
                                    <div>
                                        @for (approver of status[1]; track approver) {
                                            <span>{{ approver }}</span>
                                        }
                                    </div>
                                }
                                <!--ap tasks-->
                                @if (taskType === 3 || taskType === 4) {
                                    <div class="c-assignedToCol-flexWrapper">
                                        @for (contact of approval.approval_contact; track contact) {
                                            <span class="c-approvalContact">
                                                {{ contact.approval_contact_name }}
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        @if (showAllApproveMsg) {
                            <div class="c-margin-top--single">All contacts must approve Authorizations.</div>
                        }
                    </div>
                }
                <div class="c-margin-left--single">
                    @if (collapse) {
                        <s25-ng-table-list [items]="$any(items)"></s25-ng-table-list>
                    }
                </div>
            </div>
        }
    `,
    styles: `
        .c-assignedToCol-flexWrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .c-approvalContact {
                padding: 3px 20px 0 0;
            }
        }
    `,
})
export class S25TaskAssignComponent implements OnInit {
    @Input() items?: S25ItemI[];
    @Input() taskItem?: any;
    @Input() textValue?: string;
    @Input() approval?: Event.Workflow.Task;
    @Input() taskType?: Task.Id;

    init: boolean = false;
    isTodo: boolean = false;
    approvalState: any[];
    collapse: boolean = true;
    showAllApproveMsg: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.taskItem.taskId = this.taskItem.itemId;
        this.taskItem.state = this.taskItem.itemStateId;
        this.taskItem.overallState = this.taskItem.itemStateId;
        this.taskItem.isTodo ? (this.isTodo = true) : (this.isTodo = false);
        this.getApprovalStatuses();
        this.init = true;
    }

    contactsChange(contacts: { added: S25ItemI[]; removed: S25ItemI[] }) {
        const removed = new Set(contacts.removed.map((contact) => Number(contact.itemId)));
        this.items = this.items.filter((item) => !removed.has(Number(item.itemId)));
        for (const added of contacts.added) {
            this.items.push({ ...added, text: added.itemName });
        }
        this.cd.detectChanges();
    }

    /**
     * adds them to this.approvalState with their task status authorized, in progress etc.
     */
    getApprovalStatuses = () => {
        const messages: { [key: string]: any[] } = {};
        if (this.approval?.approval_contact && this.approval?.approval_contact?.length > 0) {
            for (let contact of this.approval?.approval_contact) {
                const status = `${contact?.notification_type_name} ${contact?.approval_contact_state_name
                    .split("/")
                    .join("/ ")}`;
                messages[status] ??= [];
                messages[status].push(contact?.approval_contact_name);
                if (this.approval?.notify_type_id === 2) {
                    if (contact?.notification_type_id === 2 && !this.showAllApproveMsg) this.showAllApproveMsg = true;
                    this.showAllApproveMsg = !!this.showAllApproveMsg;
                } else this.showAllApproveMsg = false;
            }
            this.approvalState = Object.entries(messages)
                .map(([type, arr]) => [type, arr.sort()])
                .sort();
        }
    };

    toggleCollapse() {
        this.collapse = !this.collapse;
    }
}
