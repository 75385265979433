import { TaskUpdateI } from "../../services/task/task.edit.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";

export namespace TaskUtil {
    export function findChangedContacts(resp: TaskUpdateI, data: { added?: S25ItemI[]; removed?: S25ItemI[] }) {
        const changes = {
            success: { added: [] as S25ItemI[], removed: [] as S25ItemI[] },
            failed: { added: [] as S25ItemI[], removed: [] as S25ItemI[] },
        };

        const allContacts = S25Util.array.flatten(resp.data.map((task) => task.contacts));
        const contacts = new Map(allContacts.map((contact) => [contact.contId, contact]));

        for (const added of data.added || []) {
            const contact = contacts.get(Number(added.itemId));
            if (contact) {
                added.notifyType = contact.notifyType;
                changes.success.added.push(added);
            } else {
                changes.failed.added.push(added);
            }
        }
        for (const removed of data.removed || []) {
            if (!contacts.has(Number(removed.itemId))) {
                changes.success.removed.push(removed);
            } else {
                changes.failed.removed.push(removed);
            }
        }

        return changes;
    }
}
