//author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TaskEditService, taskMicroI } from "../../services/task/task.edit.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { UserprefService } from "../../services/userpref.service";
import { FlsService } from "../../services/fls.service";
import { ContactService } from "../../services/contact.service";
import { TaskNormalizeUtil } from "../../services/task/task.normalize.util";
import { hasFullFls } from "../../pojo/Fls";
import { MaybeArray } from "../../pojo/Util";
import { TaskUtil } from "./task.util";

@TypeManagerDecorator("s25-ng-task-contacts-picker")
@Component({
    selector: "s25-ng-task-contacts-picker",
    template: `
        @if (canEdit && init) {
            <div class="buttons">
                <s25-ng-multiselect-search-criteria
                    [type]="'contacts'"
                    [popoverOnBody]="true"
                    [buttonText]="noCommit ? 'Add Users' : 'Add/Remove Users'"
                    [selectedItems]="selectedContacts"
                    [disabled]="isSaving"
                    [customFilterValue]="'&is_r25user=1'"
                    (close)="editContacts($event)"
                />
                @if (noCommit) {
                    <s25-ng-multiselect-search-criteria
                        [type]="'contacts'"
                        [popoverOnBody]="true"
                        [buttonText]="'Remove Users'"
                        [disabled]="isSaving"
                        [customFilterValue]="'&is_r25user=1'"
                        (close)="changes.emit({ unassignContact: $event })"
                    />
                }
                @if (!hideGroups) {
                    <s25-ng-multiselect-search-criteria
                        [type]="'securityGroups'"
                        [popoverOnBody]="true"
                        [buttonText]="'Add Group'"
                        [selectedItems]="selectedGroupsAssign"
                        [disabled]="isSaving"
                        (close)="assignGroups($event)"
                    />
                    <s25-ng-multiselect-search-criteria
                        [type]="'securityGroups'"
                        [popoverOnBody]="true"
                        [buttonText]="'Remove Group'"
                        [selectedItems]="selectedGroupsUnassign"
                        [disabled]="isSaving"
                        (close)="unassignGroups($event)"
                    />
                }
                @if (isSaving) {
                    <s25-ng-loading-inline-static />
                }
            </div>
        }
    `,
    styles: `
        .buttons {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            gap: 0.5rem;
            padding-block: 0.5rem;
            align-items: center;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/*
 * Given an array of tasks,
 * Allow user to select some contacts to add
 * Do a PUT to contacts
 * - detect which succeeded and which failed
 * - if the task passed in includes a list of
 */
export class S25TaskContactsPickerComponent implements OnInit {
    //eventually force to Task.Object{} - as is, there are many formats for tasks, easier to normalize in the component -tw
    @Input() tasks?: MaybeArray<any>;
    @Input() eventId?: number; //Required for BPE emails to be sent
    @Input() isOnBody = false;
    @Input() noCommit = false; // If true, will not commit changes. Instead, changes are emitted via `changes` output
    @Input() hideGroups = false; // If true, the assign/unassign by groups buttons will be hidden
    @Input() afterSave?: Function; //to support use with angJS
    @Output() contactsChange = new EventEmitter<{ added: S25ItemI[]; removed: S25ItemI[] }>();
    @Output() changes = new EventEmitter<taskMicroI>();

    normalizedTasks: Task.Object[];
    canEdit = false;
    init = false;
    selectedItems: S25ItemI[] = [];
    selectedContacts: S25ItemI[] = [];
    selectedGroupsAssign: S25ItemI[] = [];
    selectedGroupsUnassign: S25ItemI[] = [];
    isSaving = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        this.tasks = S25Util.array.forceArray(this.tasks);
        this.normalizedTasks = (this.tasks as any[]).map((task) => TaskNormalizeUtil.normalizeTaskData(task));
        if (!this.noCommit) {
            const allContacts = S25Util.array.flatten(this.normalizedTasks.map((t) => t.contacts));
            const uniqueContacts = S25Util.array.unique(allContacts, (c) => c.contId);
            this.selectedContacts = uniqueContacts.map((c) => ({ itemId: c.contId, itemName: c.contName }));

            this.canEdit = await this.canEditTasks(this.normalizedTasks);

            // Can't edit the task don't init
            if (!this.canEdit) return;

            this.eventId = this.eventId || this.normalizedTasks[0].eventId;
        } else {
            this.canEdit = true;
        }

        this.init = true;
        this.cd.detectChanges();
    }

    /*
        Can edit if
        Task is in progress
        AND Task is not a to-do (different process for todos)
        AND (
            user is -1 admin
            OR (
                user has TASK_LIST = F and VIEW_OTHERS = F
                AND user is current assignee
            )
        )
    */
    async canEditTasks(tasks: Task.Object[]): Promise<boolean> {
        const contacts = tasks[0].contacts;
        const inProgress = tasks.some((task) =>
            [Task.States.InProgress, Task.States.Various].includes(task.overallState),
        );
        const isTodo = tasks.some((task) => task.taskType === Task.WorkflowTypes.todo);

        if (!inProgress || isTodo) return Promise.resolve(false);

        const [groupId, contactId, fls] = await Promise.all([
            UserprefService.getGroupId(),
            UserprefService.getContactId(),
            FlsService.getFls(),
        ]);

        const hasFls = hasFullFls(fls.TASK_LIST) && hasFullFls(fls.VIEW_OTHERS);
        const isAssigned = contacts.some((contact) => Number(contact.contId) === Number(contactId));

        return groupId === -1 || (hasFls && isAssigned);
    }

    async editContacts(contacts: S25ItemI[]) {
        const allContacts = S25Util.array.flatten(this.normalizedTasks.map((t) => t.contacts));
        const existingContacts = S25Util.array.unique(allContacts, (c) => c.contId);
        const existing = new Set(existingContacts.map((c) => c.contId));
        const newContacts = new Set(contacts.map((c) => c.itemId));
        const changes: taskMicroI = { assignContact: [], unassignContact: [] };
        for (const c of contacts) {
            if (!existing.has(Number(c.itemId))) changes.assignContact.push(c);
        }
        for (const c of existingContacts) {
            if (!newContacts.has(c.contId)) changes.unassignContact.push({ itemId: c.contId, itemName: c.contName });
        }
        if (!changes.assignContact.length && !changes.unassignContact.length) return; // No changes

        if (this.noCommit) {
            this.changes.emit({ assignContact: changes.assignContact });
            return;
        }

        this.startSaving();
        const resp = await TaskEditService.microUpdate(this.normalizedTasks, changes, this.eventId);
        const changed = TaskUtil.findChangedContacts(resp, {
            added: changes.assignContact,
            removed: changes.unassignContact,
        });

        this.updateLocalContacts(changed, "contact");

        // Done
        this.contactsChange.emit(changed.success);
        this.stopSaving();
    }

    updateLocalContacts(changed: ReturnType<typeof TaskUtil.findChangedContacts>, via: "contact" | "group") {
        const removed = new Set(changed.success.removed.map((contact) => Number(contact.itemId)));

        if (via === "contact") {
            for (const removed of changed.failed.removed) this.selectedContacts.push(removed);
            const failedToAdd = new Set(changed.failed.added.map((contact) => contact.itemId));
            S25Util.array.inplaceFilter(this.selectedContacts, (item) => !failedToAdd.has(item.itemId));
        } else if (via === "group") {
            S25Util.array.inplaceFilter(this.selectedContacts, (contact) => !removed.has(Number(contact.itemId)));
            Array.prototype.push.apply(this.selectedContacts, changed.success.added);
        }

        for (const task of this.normalizedTasks) {
            task.contacts = task.contacts.filter((contact) => !removed.has(contact.contId));
            for (const added of changed.success.added) {
                task.contacts.push({ contId: Number(added.itemId), contName: added.itemName } as Task.Contact);
            }
        }
    }

    async assignGroups(groups: S25ItemI[]) {
        if (this.noCommit) return this.changes.emit({ assignGroup: groups });

        this.startSaving();
        const resp = await TaskEditService.microUpdate(this.normalizedTasks, { assignGroup: groups }, this.eventId);

        let addedContacts = await ContactService.getContactsByGroup(groups.map((group) => Number(group.itemId)));
        const allContacts = S25Util.array.flatten(this.normalizedTasks.map((t) => t.contacts));
        const existingContacts = new Set(allContacts.map((c) => c.contId));
        addedContacts = addedContacts.filter((contact) => !existingContacts.has(Number(contact.itemId)));

        const changes = TaskUtil.findChangedContacts(resp, { added: addedContacts });
        this.updateLocalContacts(changes, "group");

        this.selectedGroupsAssign.splice(0, this.selectedGroupsAssign.length); // Reset selection
        this.contactsChange.emit(changes.success);
        this.stopSaving();
    }

    async unassignGroups(groups: S25ItemI[]) {
        if (this.noCommit) return this.changes.emit({ unassignGroup: groups });

        this.startSaving();
        const resp = await TaskEditService.microUpdate(this.normalizedTasks, { unassignGroup: groups }, this.eventId);

        let removedContacts = await ContactService.getContactsByGroup(groups.map((group) => Number(group.itemId)));
        const allContacts = S25Util.array.flatten(this.normalizedTasks.map((t) => t.contacts));
        const existingContacts = new Set(allContacts.map((c) => c.contId));
        removedContacts = removedContacts.filter((contact) => existingContacts.has(Number(contact.itemId)));

        const changes = TaskUtil.findChangedContacts(resp, { removed: removedContacts });
        this.updateLocalContacts(changes, "group");

        this.selectedGroupsUnassign.splice(0, this.selectedGroupsUnassign.length); // Reset selection
        this.contactsChange.emit(changes.success);
        this.stopSaving();
    }

    startSaving() {
        if (this.noCommit) return; // No loading for bulk edit
        this.isSaving = true;
        this.cd.detectChanges();
    }

    stopSaving() {
        this.isSaving = false;
        this.cd.detectChanges();
        this.afterSave?.();
    }
}
